import React, { useEffect, useState } from "react";
type AnimationName = "zoom";
const MAX_SECOND = 10;
interface AnimateProps {
  animation?: AnimationName;
  animationDelayIn?: number;
  animationDelayOut?: number;
  animationDurationIn?: number;
  animationDurationOut?: number;
  isVisible: boolean;
  children: React.ReactNode;
}
import styles from "./animate.module.css";
const Animate: React.FunctionComponent<AnimateProps> = ({
  children,
  isVisible,
  animation = "zoom",
  animationDelayIn = 0,
  animationDelayOut = 0,
  animationDurationIn = 1,
  animationDurationOut = 1,
}: AnimateProps) => {
  const [render, setRender] = useState<boolean>(isVisible ?? false);
  const [_animationIn, setAnimationDurationIn] = useState<string>();
  const [_animationOut, setAnimationDurationOut] = useState<string>();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isVisible) timer = setTimeout(() => setRender(true), animationDelayIn);
    else timer = setTimeout(() => setRender(false), animationDelayOut);
    return () => clearTimeout(timer);
  }, [isVisible]);

  useEffect(() => {
    const InUnit = animationDurationIn > MAX_SECOND ? "ms" : "s";
    const OutUnit = animationDurationOut > MAX_SECOND ? "ms" : "s";
    const animationIn = `${animation}In ${animationDurationIn + InUnit} linear`;
    const animationOut = `${animation}Out ${
      animationDurationOut + OutUnit
    } linear`;
    setAnimationDurationIn(animationIn);
    setAnimationDurationOut(animationOut);
  }, []);

  const onAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === "zoomOut") {
      setRender(false);
    }
  };
  return render ? (
    <div
      className={styles.animatedContainer}
      style={{ animation: `${isVisible ? _animationIn : _animationOut}` }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};

export default Animate;
