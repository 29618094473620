import React, { ChangeEvent } from "react";
import { Loader } from "react-feather";
import styles from "./button.module.css";

type ButtonType = "primary" | "secondary";

interface ButtonInterface {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: ButtonType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

const Button: React.FunctionComponent<ButtonInterface> = ({
  children,
  icon,
  className,
  disabled = false,
  loading = false,
  type = "primary",
  onClick,
}: ButtonInterface) => {
  // Rendering

  let rootClass =
    type === "secondary"
      ? `${styles.button} ${styles.secondary}`
      : styles.button;
  if (className) rootClass += ` ${className}`;

  const _onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) onClick();

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <button
      disabled={disabled || loading}
      className={rootClass}
      onClick={_onClick}
    >
      {loading ? <Loader className="loader" /> : icon}
      {children}
    </button>
  );
};

export default Button;
