import React from "react";
import { CategoryReport, VigieInfoReport } from "../../../../models/vigie-info";
import { EffectiveChange } from "../../../../components";
import styles from "./report.module.css";
import texts from "../../../../constants/texts";
import { Info } from "react-feather";
import { DateTime } from "luxon";

interface Props {
  vigieInfo: CategoryReport;
}
const ReportTab: React.FunctionComponent<Props> = ({ vigieInfo }: Props) => {
  if (Array.isArray(vigieInfo)) return null;
  return (
    <div className={styles.reportContainer}>
      <>
        <h5>
          {`${DateTime.fromISO(vigieInfo.interval.startDate)
            .toUTC(0)
            .toLocaleString({
              dateStyle: "long",
            })} ~ ${DateTime.fromISO(vigieInfo.interval.endDate)
            .toUTC(0)
            .toLocaleString({
              dateStyle: "long",
            })}`}
        </h5>
        <h1>{texts.common.mapRateCategory("mortgage")}</h1>
        {vigieInfo?.mortgage?.length === 0 && (
          <div className={styles.errorContainer}>
            <Info className="mx-2" />
            <span>{texts.common.noInterdayChange}</span>
          </div>
        )}

        {vigieInfo?.mortgage?.map((x: VigieInfoReport) => (
          <div key={x.key}>
            {x.value !== "" && <h3>{x.value}</h3>}
            <EffectiveChange effectiveChange={x.potentialErrors} />
          </div>
        ))}
        <h1>{texts.common.mapRateCategory("savings")}</h1>
        {vigieInfo?.savings?.length === 0 && (
          <div className={styles.errorContainer}>
            <Info className="mx-2" />
            <span>{texts.common.noInterdayChange}</span>
          </div>
        )}

        {vigieInfo?.savings?.map((x: VigieInfoReport) => (
          <div key={x.key}>
            {x.value !== "" && <h3>{x.value}</h3>}
            <EffectiveChange effectiveChange={x.potentialErrors} />
          </div>
        ))}
      </>
    </div>
  );
};

export default ReportTab;
