import CibcLogo from "../assets/images/cibc-logo.svg";
import B2bLogo from "../assets/images/b2b-logo.svg";
import BmoLogo from "../assets/images/bmo-logo.svg";
import BncLogo from "../assets/images/bnc-logo-v2.svg";
import RbcLogo from "../assets/images/rbc-logo.svg";
import TangerineLogo from "../assets/images/tangerine-logo.svg";
import EpqLogo from "../assets/images/epq-logo.svg";
import LaurentinneLogo from "../assets/images/laurentienne-logo.svg";
import ScotiaLogo from "../assets/images/scotiabank-logo.svg";
import TdLogo from "../assets/images/td-logo.svg";
import ManuLifeLogo from "../assets/images/manulife-logo.svg";
import HsbcLogo from "../assets/images/hsbc-logo-v3.svg";
import UnknownBankLogo from "../assets/images/unknown-bank.svg";
import { EffectiveChangeObj } from "./effective-change";

export interface KeyValueObj {
  key: string;
  value: string;
  approvedAt: string | null;
  rejectedAt: string | null;
  order: number;
}

export interface BankObj {
  identifier: string;
  nameFr: string;
  nameEn: string;
  approvedAt: string | null;
  rejectedAt: string | null;
}

export interface RateTypeObject extends KeyValueObj {
  banks: BankObj[];
  rejectedAt: string | null;
  approvedAt: string | null;
}

export type ImplementedVigieCategory = "mortgage" | "savings";

export type VigieType = {
  [K in ImplementedVigieCategory]: KeyValueObj[];
};

export interface VigieInfobyType {
  rateCategory: ImplementedVigieCategory;
  rateType: RateTypeObject[];
}
export type VigieInfoByBanks = BankObj & { vigie: VigieType };
export interface VigieInfoReport {
  key: string;
  value: string;
  potentialErrors: EffectiveChangeObj[];
}
export interface CategoryReport {
  mortgage: VigieInfoReport[];
  savings: VigieInfoReport[];
  interval: { startDate: string; endDate: string };
}

export const mapBankImgToIdentifier = (identifier: string): string => {
  switch (identifier) {
    case "b2b":
      return B2bLogo;
    case "bmo":
      return BmoLogo;
    case "bnc":
      return BncLogo;
    case "cibc":
      return CibcLogo;
    case "epq":
      return EpqLogo;
    case "hsbc":
      return HsbcLogo;
    case "laurentienne":
      return LaurentinneLogo;
    case "manuvie":
      return ManuLifeLogo;
    case "rbc":
      return RbcLogo;
    case "scotia":
      return ScotiaLogo;
    case "tangerine":
      return TangerineLogo;
    case "td":
      return TdLogo;
    default:
      return UnknownBankLogo;
  }
};
