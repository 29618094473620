import React, { useState } from "react";
import {
  ChangeObj,
  EffectiveChangeObj,
  RateStatus,
} from "../../models/effective-change";
import styles from "./effective-change.module.css";
import { mapBankImgToIdentifier } from "../../models/vigie-info";
import texts from "../../constants/texts";
import { ArrowRightCircle } from "react-feather";
import { Button, Divider, Field } from "../index";

interface Props {
  effectiveChange: EffectiveChangeObj[];
  onEffectiveChangeAccept?: (url: string) => void;
}
const EffectiveChange: React.FunctionComponent<Props> = ({
  effectiveChange,
  onEffectiveChangeAccept,
}: Props) => {
  const t = texts.components.effectiveChange;
  const [infoUrl, setInfoUrl] = useState<string>("");

  const renderEffectiveChangeByStatus = (
    rateStatus: RateStatus,
    changeObjArr: ChangeObj[]
  ) => {
    if (rateStatus === "deleted")
      return renderEffectiveChangeDeleted(changeObjArr);

    return (
      <div className={styles.bankModificationContainer}>
        <div className="d-flex flex-column">
          <h4 className={`${styles.col4} ${styles[rateStatus]}`}>
            {t[rateStatus]}
          </h4>
          <div
            className={`${styles.evenColumns} ${styles.header} ${styles.col8}`}
          >
            <h6 className={styles.col} style={{ flex: "1 1 26%" }}>
              {t.detailsHeader}
            </h6>
            <span className={styles.col} />
            <h6 className={styles.col}>{t.oldValueHeader}</h6>
            <span className={styles.col} />
            <h6 className={styles.col}>{t.newValueHeader}</h6>
          </div>
          <Divider />
          {changeObjArr.map((changeObj, index) => (
            <div key={index} className="d-flex my-1 align-center">
              <div className={`${styles.col4} d-flex flex-column`}>
                <span className={styles.col}>{changeObj.details}</span>
                <span>{changeObj.subDetails}</span>
              </div>
              <div className={`${styles.evenColumns} ${styles.col8}`}>
                <span className={`${styles.desktopOnly}`}>
                  <ArrowRightCircle className={styles.col} />
                </span>
                <span className={`${styles.col} ${styles.oldValue}`}>
                  {renderBasedOnMixedType(changeObj.oldValue)}
                </span>
                <span className={`${styles.desktopOnly}`}>
                  <ArrowRightCircle className={styles.col} />
                </span>
                <span className={`${styles.col} ${styles.newValue}`}>
                  {changeObj.newValue ?? texts.common.noValue}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBasedOnMixedType = (oldValue: any) => {
    if (Array.isArray(oldValue)) {
      return oldValue.map((x) => {
        return typeof x === "object" ? (
          <div className="d-flex flex-column">
            <>
              {Object.entries(x).map(([key, value]: [string, any]) => (
                <div key={key} className={styles.col}>
                  <span>{key}: </span>
                  <span style={{ maxHeight: "10px" }}>
                    {renderBasedOnMixedType(value ?? texts.common.noValue)}
                  </span>
                </div>
              ))}
            </>
          </div>
        ) : (
          x
        );
      });
    } else if (typeof oldValue === "object") {
      return (
        <div className="d-flex flex-column">
          <>
            {Object.entries(oldValue).map(([key, value]: [string, any]) => (
              <div key={key}>
                <span>{key}: </span>
                <span style={{ maxHeight: "10px" }}>
                  {JSON.stringify(value) ?? texts.common.noValue}
                </span>
              </div>
            ))}
          </>
        </div>
      );
    } else {
      return oldValue;
    }
  };
  const renderEffectiveChangeDeleted = (changeObjArr: ChangeObj[]) => {
    return (
      <div className={styles.bankModificationContainer}>
        <div className="d-flex flex-column">
          <h4 className={`${styles.col4} ${styles["deleted"]}`}>
            {t["deleted"]}
          </h4>
          <div className={`${styles.evenColumns} ${styles.header}`}>
            <h6 className={styles.col8} style={{ flex: "1 1 100%" }}>
              {t.detailsHeader}
            </h6>
            <h6 className={styles.col4} style={{ flex: "1 1 100%" }}>
              {t.deletedValue}
            </h6>
          </div>
          <Divider />
          {changeObjArr.map((changeObj, index) => (
            <div key={index} className={`d-flex my-1 align-center`}>
              <div
                className={`${styles.col4} d-flex flex-column`}
                style={{ flexBasis: "0%" }}
              >
                <span>{changeObj.details}</span>
                <span>{changeObj.subDetails}</span>
                <hr />
              </div>
              <span className={`${styles.col2}  text-center`}>
                <ArrowRightCircle />
              </span>
              <div className={`${styles.col6}`}>
                <span className={`${styles.oldValue}`}>
                  {renderBasedOnMixedType(changeObj.oldValue)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.effChangeContainer} my-3`}>
      <div className={styles.effChangeHeader}>
        <h3>
          {onEffectiveChangeAccept ? t.effectiveChangeTitle : t.todaysChange}
        </h3>
        {onEffectiveChangeAccept && (
          <div className="d-flex">
            <Field
              onChange={(e) => setInfoUrl(e.target.value)}
              className={styles.linkField}
              placeholder={t.infoUrl}
            />
            <Button onClick={() => onEffectiveChangeAccept(infoUrl)}>
              {texts.common.send}
            </Button>
          </div>
        )}
      </div>
      {effectiveChange.map((effChangeObj) => (
        <>
          <img
            className="mt-5 mb-2"
            src={mapBankImgToIdentifier(effChangeObj.identifier)}
            height="48px"
            width="48px"
          />
          <div>
            {(Object.entries(effChangeObj.diff) as [
              RateStatus,
              ChangeObj[]
            ][]).map(([rateStatus, changeObjArr]) =>
              renderEffectiveChangeByStatus(rateStatus, changeObjArr)
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default EffectiveChange;
