import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import styles from "./home.module.css";
import { VigieInfoByBanks, VigieInfobyType } from "../../models/vigie-info";
import Axios from "axios";
import { VIGIE_INFO } from "../../constants/endpoints";
import { ToggleButton } from "../../components";
import { Briefcase, FileText, Percent } from "react-feather";
import CustomTabs from "./tabs";

const HomePage: React.FunctionComponent = () => {
  const [vigieInfo, setVigieInfo] = useState<
    VigieInfoByBanks[] | VigieInfobyType[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    "type"
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchVigieInfoBanks();
  }, []);

  useEffect(() => {
    fetchVigieInfoBanks();
  }, [selectedFilter]);

  const fetchVigieInfoBanks = async () => {
    try {
      setIsLoading(true);

      const { data } = await Axios.get<VigieInfoByBanks[]>(
        VIGIE_INFO(selectedFilter ?? "type")
      );
      setVigieInfo(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onVigieAccept = (data: any) => {
    setVigieInfo(data);
  };
  const onChange = (value: string) => {
    setSelectedFilter(value);
    setVigieInfo([]);
  };
  return (
    <div className={styles.homeContainer}>
      <div>
        <ToggleButton
          onChange={onChange}
          className="my-2"
          items={[
            { text: "Type", icon: <Percent />, value: "type" },
            { text: "Banque", icon: <Briefcase />, value: "bank" },
            { text: "Rapport", icon: <FileText />, value: "report" },
          ]}
        />
      </div>
      <CustomTabs
        onVigieAccept={onVigieAccept}
        onVigieReject={(data) => setVigieInfo(data)}
        isLoading={isLoading}
        vigieInfo={vigieInfo}
        selectedTabKey={selectedFilter}
      />
    </div>
  );
};
export default HomePage;
