import React, { ChangeEvent, useState } from "react";
import Axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Field } from "../../components";
import texts from "../../constants/texts";
import styles from "./login.module.css";
import { ROUTES } from "../../constants/routes";
import BigLogoSrc from "../../assets/images/big-logo.svg";
import { LOGIN } from "../../constants/endpoints";
import useAuthToken from "../../hooks/auth-token";

const LoginPage: React.FunctionComponent = () => {
  // States and refs

  const [, setAuthToken] = useAuthToken();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const location = useLocation<any>();
  const t = texts.auth.login;

  const { from } = location.state || { from: { pathname: ROUTES.HOME } };

  // Validation

  const usernameError = username === "" ? t.errors.invalidUsername : null;
  const passwordError =
    password.length === 0 ? t.errors.passwordRequired : null;
  const hasError = usernameError !== null || passwordError !== null;

  // Network

  const login = async () => {
    setSubmitted(true);

    if (hasError) return;

    setLoading(true);
    try {
      const { data } = await Axios.post(LOGIN, { username, password });
      setAuthToken(data.token);
      history.replace(from);
    } catch (error) {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div className={styles.page}>
      <div className={`${styles.container}`}>
        <img src={BigLogoSrc} />
        <h1>{t.connect}</h1>

        <div className="fieldHolder">
          <label>{t.username}</label>
          <Field
            placeholder={t.yourUsername}
            value={username}
            error={submitted ? usernameError : null}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
          />
        </div>

        <div className="fieldHolder">
          <label>
            {t.password} <span className={styles.light}>|</span>{" "}
          </label>
          <Field
            placeholder={t.yourPassword}
            type="password"
            value={password}
            error={submitted ? passwordError : null}
            onPressEnter={login}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </div>

        <Button
          loading={loading}
          onClick={login}
          disabled={submitted && hasError}
        >
          {t.connection}
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
