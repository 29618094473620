import { ImplementedVigieCategory } from "../models/vigie-info";

export const LOGIN = "/login";
export const ME = "/users/me";
export const VIGIE_INFO = (groupBy: string) =>
  `/vigie-info?group-by=${groupBy}`;
export const VIGIE_RATE = (
  rateCategory: ImplementedVigieCategory,
  rateTypeKey: string
) => {
  switch (rateCategory) {
    case "mortgage":
      return "/mortgage-rates";
    case "savings":
      switch (rateTypeKey) {
        case "refundableGIC":
          return "/savings-rates/refundable-gic";
        case "tfsaRangeRate":
          return "/savings-rates/tfsa-account";
        case "highInterestRangeRate":
          return "/savings-rates/high-interest-account";
        case "enterpriseRangeRate":
          return "/savings-rates/enterprise-account";
        case "nonRefundableGIC":
          return "/savings-rates/non-refundable-gic";
        case "promotionalGIC":
          return "/savings-rates/promotional-gic";
        case "promotionalRefundableGIC":
          return "/savings-rates/promo-refundable-gic";
        default:
          return "/savings-rates";
      }
  }
};
export const EFFECTIVE_CHANGE = (
  rateCategory: ImplementedVigieCategory,
  rateTypeKey: string
) => `${VIGIE_RATE(rateCategory, rateTypeKey)}/effective-change`;

export const ACCEPT_VIGIE = (
  rateCategory: ImplementedVigieCategory,
  rateTypeKey: string
) => `${VIGIE_RATE(rateCategory, rateTypeKey)}/accept`;
export const REJECT_VIGIE = (
  rateCategory: ImplementedVigieCategory,
  rateTypeKey: string
) => `${VIGIE_RATE(rateCategory, rateTypeKey)}/reject`;
