import { createContext } from "react";
import User from "../models/user";
import {
  getPersistedAuthToken,
  persistAuthToken,
} from "../utils/local-storage";

interface AppContextInterface {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  authUser: User | null;
  setAuthUser: (user: User | null) => void;
}

const context: AppContextInterface = {
  authToken: getPersistedAuthToken(),
  setAuthToken: (token: string | null) => persistAuthToken(token),
  authUser: null,
  setAuthUser: () => {
    console.log("Not implemented");
  },
};

const AppContext = createContext(context);

export default AppContext;
