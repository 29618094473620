import React from "react";
import styles from "./loader.module.css";
import texts from "../../constants/texts";
interface Props {
  show?: boolean;
}
const Loader: React.FunctionComponent<Props> = ({ show = false }: Props) => {
  const loader = (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} />
      <p>{texts.common.loadingVigie}</p>
    </div>
  );
  return show ? loader : null;
};

export default Loader;
