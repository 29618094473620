import React from "react";
import styles from "./round-profile-image.module.css";
import { CheckCircle, XCircle } from "react-feather";

interface RoundProfileImageInterface {
  className?: string;
  approved?: boolean;
  rejected?: boolean;
  imageSrc?: string;
}

const RoundProfileImage: React.FunctionComponent<RoundProfileImageInterface> = ({
  className,
  approved,
  rejected,
  imageSrc,
}: RoundProfileImageInterface) => {
  // Rendering

  let rootClass = styles.holder;
  if (className) rootClass += ` ${className}`;

  const style = imageSrc
    ? { backgroundImage: `url("${imageSrc}")` }
    : undefined;

  let status = null;
  if (approved)
    status = (
      <span className={styles.accepted}>
        <CheckCircle />
      </span>
    );
  else if (rejected)
    status = (
      <span className={styles.rejected}>
        <XCircle />
      </span>
    );
  return (
    <div className={rootClass} style={style}>
      {status}
    </div>
  );
};

export default RoundProfileImage;
