import {
  ImplementedVigieCategory,
  RateTypeObject,
  mapBankImgToIdentifier,
  VigieInfobyType,
} from "../../../../models/vigie-info";
import Axios from "axios";
import React, { useState } from "react";
import texts from "../../../../constants/texts";
import { TabsProps } from "../index";
import {
  Button,
  Divider,
  EffectiveChange,
  Popover,
  RoundProfileImage,
  VigieRejectionForm,
} from "../../../../components";
import { PopoverShowObj } from "../banks";
import {
  ACCEPT_VIGIE,
  REJECT_VIGIE,
  VIGIE_RATE,
} from "../../../../constants/endpoints";
import { EffectiveChangeInfo } from "../../../../models/effective-change";
import { AlertTriangle, Check, X } from "react-feather";
import styles from "../tab.module.css";
import useAuthUser from "../../../../hooks/auth-user";
import get from "lodash.get";
import { NotificationManager } from "react-notifications";

export interface PopoverEffectiveChange {
  [key: string]: EffectiveChangeInfo;
}
const TypeTabs: React.FunctionComponent<TabsProps> = ({
  vigieInfo,
  onVigieAccept,
  onVigieReject,
}: TabsProps) => {
  // State
  const [popoverIsShownObj, setPopoverIsShowObj] = useState<PopoverShowObj>({});
  const [downloadIsLoading, setDownloadIsLoading] = useState<boolean>(false);
  const [acceptIsLoading, setAcceptIsLoading] = useState<boolean>(false);
  const [authUser] = useAuthUser();
  const [
    effectiveChange,
    setEffectiveChange,
  ] = useState<PopoverEffectiveChange>({});

  const t = texts.components.tabs;

  // Networking
  const downloadVigie = async (
    rateCategory: ImplementedVigieCategory,
    rateInfo: RateTypeObject
  ) => {
    setDownloadIsLoading(true);
    const today = new Date().toISOString().split("T")[0];
    const mortgageRateType =
      rateCategory === "mortgage" ? `&rate-type=${rateInfo.key}` : "";

    const bankIdentifiers =
      rateInfo.rejectedAt !== null || rateInfo.approvedAt !== null
        ? ""
        : `&bank-identifiers=${rateInfo.banks
            .filter((x) => x.rejectedAt === null && x.approvedAt === null)
            .map((y) => y.identifier)
            .join(",")}`;

    const response = await Axios.get(
      `${VIGIE_RATE(
        rateCategory,
        rateInfo.key
      )}/temp?dates=${today}&format=xlsx${mortgageRateType}${bankIdentifiers}`,
      { responseType: "blob" }
    );

    setDownloadIsLoading(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${rateCategory.toUpperCase()}_${rateInfo.key.toUpperCase()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const acceptVigie = async (
    categorie: ImplementedVigieCategory,
    rateInfo: RateTypeObject
  ) => {
    setAcceptIsLoading(true);
    const mortgageRateType =
      categorie === "mortgage" ? `&rate-type=${rateInfo.key}` : "";
    const url = `${ACCEPT_VIGIE(
      categorie,
      rateInfo.key
    )}?bank-identifiers=${rateInfo.banks
      .filter((y) => y.rejectedAt === null)
      .map((u) => u.identifier)
      .join(",")}${mortgageRateType}`;

    const { data } = await Axios.put(url);
    onVigieAccept(data);
    setAcceptIsLoading(false);
  };

  const onEffectiveChangeAccept = async (
    popoverKey: string,
    categorie: ImplementedVigieCategory,
    rateKey: string,
    infoUrl?: string
  ) => {
    const payload = new FormData();
    const { file, justification } = effectiveChange[popoverKey];

    if (!file || !justification) return;
    payload.append("file", file);
    payload.append("justification", justification);
    payload.append(
      "effectiveChange",
      JSON.stringify(effectiveChange[popoverKey].effectiveChange)
    );
    if (infoUrl) payload.append("url", infoUrl);

    const axiosInstance = Axios.create({
      baseURL: process.env.REACT_APP_API_URL || "http://localhost:9000",
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authUser?.token}`,
      },
    };

    const mortgageRateType =
      categorie === "mortgage" ? `?rate-type=${rateKey}` : "";
    const url = `${REJECT_VIGIE(categorie, rateKey)}${mortgageRateType}`;

    try {
      const { data } = await axiosInstance.put(url, payload, config);
      const effectiveChangeCopy = { ...effectiveChange };
      delete effectiveChangeCopy[popoverKey];
      setEffectiveChange(effectiveChangeCopy);
      onVigieReject(data);
    } catch (error) {
      const msg = get(
        error,
        "response.data.error.msg",
        texts.notification.unexpectedError
      );
      NotificationManager.error(msg);
      console.log(error);
    }
  };
  // Handlers

  const onVigieEffectiveChangeReceived = (
    effChange: any,
    popoverKey: string
  ) => {
    setEffectiveChange({
      ...effectiveChange,
      [popoverKey]: effChange,
    });
    setPopoverIsShowObj({
      ...popoverIsShownObj,
      [popoverKey]: false,
    });
  };

  // Rendering

  const renderRejectionPopOver = (
    categorie: ImplementedVigieCategory,
    rateType: RateTypeObject
  ) => {
    const popoverKey = `popover-${rateType.key}`;
    const isShown = popoverIsShownObj[popoverKey] ?? false;
    return (
      <div className={styles.rejectionHolder}>
        {
          <Popover
            onChange={(isShown) =>
              setPopoverIsShowObj({
                ...popoverIsShownObj,
                [popoverKey]: isShown,
              })
            }
            key={popoverKey}
            align="center"
            position="top"
            shown={isShown}
          >
            <VigieRejectionForm
              setEffectiveChange={(eff) =>
                onVigieEffectiveChangeReceived(eff, popoverKey)
              }
              rateKey={rateType.key}
              rateCategory={categorie}
            />
          </Popover>
        }
      </div>
    );
  };

  const renderTabContent = (
    rateType: RateTypeObject,
    categorie: ImplementedVigieCategory
  ) => {
    let statusText = null;
    if (rateType.approvedAt !== null)
      statusText = (
        <h4 className={`${styles.isApproved} my-1`}>
          {t.acceptedAt(rateType.approvedAt)}
        </h4>
      );
    else if (rateType.rejectedAt !== null)
      statusText = (
        <h4 className={`${styles.isRejected} my-1`}>
          {t.rejectedAt(rateType.rejectedAt)}
        </h4>
      );
    return (
      <div className={styles.vigieInfoContainer}>
        <h2 className="my-3">Vigie {rateType.value}</h2>
        {statusText}
        <div className={`${styles.mobileOnly} d-flex justify-space-between`}>
          <h4 className="d-flex flex-wrap">
            {rateType.banks.map((bank) => (
              <RoundProfileImage
                key={`img_${bank.identifier}`}
                className="mx-2"
                imageSrc={mapBankImgToIdentifier(bank.identifier)}
                rejected={bank.rejectedAt !== null}
                approved={bank.approvedAt !== null}
              />
            ))}
          </h4>

          <div className="d-flex align-center justify-space-between my-2">
            <div className={styles.actionBtnContainer}>
              {rateType.rejectedAt === null && (
                <Button
                  onClick={() => acceptVigie(categorie, rateType)}
                  disabled={acceptIsLoading || !!rateType.approvedAt}
                  type={"secondary"}
                >
                  {texts.common.accept}
                  {rateType.approvedAt && (
                    <span className={styles.isApproved}>
                      <Check />
                    </span>
                  )}
                </Button>
              )}
              {rateType.approvedAt === null && (
                <>
                  <Button
                    type={"secondary"}
                    disabled={rateType.rejectedAt !== null}
                    onClick={() =>
                      setPopoverIsShowObj({
                        ...popoverIsShownObj,
                        [`popover-${rateType.key}`]: true,
                      })
                    }
                  >
                    {texts.common.reject}
                    {rateType.rejectedAt !== null && (
                      <span className={styles.isRejected}>
                        <X />
                      </span>
                    )}
                  </Button>
                  {renderRejectionPopOver(categorie, rateType)}
                </>
              )}
              <Button
                onClick={() => downloadVigie(categorie, rateType)}
                loading={downloadIsLoading}
              >
                {texts.common.download}
              </Button>
            </div>
          </div>
        </div>
        {renderEffectiveChange(
          `popover-${rateType.key}`,
          categorie,
          rateType.key
        )}
        <Divider />
      </div>
    );
  };

  const renderEffectiveChange = (
    popoverKey: string,
    categorie: ImplementedVigieCategory,
    rateKey: string
  ) => {
    if (effectiveChange[popoverKey]) {
      return (
        <EffectiveChange
          onEffectiveChangeAccept={(url: string) =>
            onEffectiveChangeAccept(popoverKey, categorie, rateKey, url)
          }
          effectiveChange={effectiveChange[popoverKey].effectiveChange}
        />
      );
    }
  };

  return (
    <>
      {Array.isArray(vigieInfo) && vigieInfo.length === 0 && (
        <div className={styles.errorContainer}>
          <AlertTriangle className="mx-2" />
          <span>{texts.common.noRateAggregated}</span>
        </div>
      )}
      {Array.isArray(vigieInfo) &&
        vigieInfo.length > 0 &&
        (vigieInfo as VigieInfobyType[]).map((type) => (
          <div
            className={styles.tabInfoContainer}
            key={`${type.rateCategory}_panel`}
          >
            <h1 className="mb-0">
              Vigie {texts.common.mapRateCategory(type.rateCategory)}
            </h1>
            {type.rateType
              .sort((a, b) => a.order - b.order)
              .map((x) => renderTabContent(x, type.rateCategory))}
          </div>
        ))}
    </>
  );
};

export default TypeTabs;
