import React from "react";
import { Link } from "react-router-dom";
import useAuthUser from "../../hooks/auth-user";
import BigLogoSrc from "../../assets/images/big-logo.svg";
import SmallLogoSrc from "../../assets/images/small-logo.svg";
import styles from "./header.module.css";
import texts from "../../constants/texts";
import { ROUTES } from "../../constants/routes";
import useAuthToken from "../../hooks/auth-token";

const Header: React.FunctionComponent = () => {
  const [, setAuthToken] = useAuthToken();
  const [authUser, setAuthUser] = useAuthUser();
  const t = texts.header;

  const logoutUser = () => {
    setAuthToken(null);
  };

  const renderAuthHeader = () => (
    <div className={styles.holder}>
      <Link className={styles.desktopLogo} to={ROUTES.HOME}>
        <img alt={t.logoDesjardins} src={BigLogoSrc} />
      </Link>
      <Link className={styles.mobileLogo} to={ROUTES.HOME}>
        <img alt={t.logoDesjardins} src={SmallLogoSrc} />
      </Link>
      <div className={styles.right}>
        <div style={{ position: "relative" }}>
          <p className={styles.noAccountDisclaimer}>
            <a onClick={logoutUser}>{t.logout}</a>
          </p>
        </div>
      </div>
    </div>
  );

  const renderPreAuthHeader = () => (
    <div className={styles.headerContainer}>
      <div className={`${styles.holder} container`}>
        <Link to={ROUTES.LOGIN}>
          <img alt={t.logoDesjardins} src={BigLogoSrc} />
        </Link>
      </div>
    </div>
  );

  return authUser ? renderAuthHeader() : renderPreAuthHeader();
};

export default Header;
