import React, { useState } from "react";
import styles from "./toggle-button.module.css";
interface ToggleButtonItem {
  value: string;
  text?: string;
  icon?: React.ReactNode;
}
interface ToggleButtonProps {
  color?: string;
  items: ToggleButtonItem[];
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: ToggleButtonItem;
}
const ToggleButton: React.FunctionComponent<ToggleButtonProps> = ({
  items,
  className,
  defaultValue,
  onChange,
  color = "var(--main-decorator-color)",
}: ToggleButtonProps) => {
  const [active, setKeyActive] = useState<string | undefined>(
    defaultValue ? defaultValue.value : items[0].value
  );
  const onButtonClick = (value: string) => {
    setKeyActive(value);
    if (onChange) onChange(value);
  };
  return (
    <div
      className={`${styles.itemGroup} ${styles.btnElevation} ${styles.btnToggle} ${styles.btnToggleGroup} ${className}`}
      style={{ color: color, caretColor: color }}
    >
      {items.map((item, index) => (
        <button
          key={index}
          onClick={() => onButtonClick(item.value)}
          type={"button"}
          value={item.value}
          className={`${styles.btn} ${styles.btnElevation}  ${
            active === item.value ? styles.btnActive : ""
          } ma-0`}
        >
          <span className={`${styles.btnContent}`}>
            {item.icon && <span className="mx-1">{item.icon} </span>}
            {item.text && <span className="mx-1"> {item.text}</span>}
          </span>
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
