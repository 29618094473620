import React, { useState, useEffect, DragEvent, ChangeEvent } from "react";
import { FileText, AlertTriangle, Loader, CheckCircle } from "react-feather";
import texts from "../../constants/texts";
import styles from "./file-upload.module.css";

const MAX_FILE_SIZE = 1024 * 1024 * 20; // 20MB
const ALLOWED_FILE_TYPES = [
  "text/csv",
  "image/png",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface ImageUploadInterface {
  className?: string;
  error?: string;
  filename?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (file: File) => void;
}

const FileUpload: React.FunctionComponent<ImageUploadInterface> = ({
  className,
  error,
  filename,
  disabled,
  loading,
  onChange,
}: ImageUploadInterface) => {
  // States and refs

  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [_filename, setFileName] = useState<string | undefined>(filename);
  const [_error, setError] = useState<string | undefined>(error);

  // Effects

  useEffect(() => {
    if (selectedFile) {
      extractBase64UrlFromFile(selectedFile);
      if (onChange) onChange(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (error !== undefined) setError(error);
  }, [error]);

  // Utils

  const extractBase64UrlFromFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = (test) => {
      setFileName(file.name);
      setError(undefined);
    };
    reader.onerror = () => {
      setError(texts.components.fileUpload.readingFile);
    };
    reader.readAsDataURL(file);
  };

  // Handlers

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files.length === 1)
      handleFilesUpdate(event.dataTransfer.files[0]);
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return;
    if (event.target.files.length === 1)
      handleFilesUpdate(event.target.files[0]);
  };

  const handleFilesUpdate = (file: File) => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError(texts.components.fileUpload.invalidFileType);
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError(texts.components.fileUpload.fileTooLarge);
      return;
    }

    setSelectedFile(file);
  };

  // Rendering

  let rootClass = styles.holder;
  if (className) rootClass += ` ${className}`;
  if (dragging) rootClass += ` ${styles.dragging}`;
  if (disabled || loading) rootClass += ` ${styles.disabled}`;

  let icon = <FileText />;
  if (_error) icon = <AlertTriangle />;
  if (loading) icon = <Loader className="loader" />;

  let infoMessage = texts.components.fileUpload.uploadFile;
  if (_error) infoMessage = _error;
  if (loading) infoMessage = "";

  const showErrorInfo = !_filename || _error || loading;

  if (_filename) {
    infoMessage = _filename;
    icon = <CheckCircle />;
  }

  return (
    <div
      className={rootClass}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onChange={_onChange}
    >
      <input accept={ALLOWED_FILE_TYPES.join(", ")} type="file" />

      {showErrorInfo && (
        <div className={styles.content}>
          {icon}
          <label>{infoMessage}</label>
        </div>
      )}

      {_filename && (
        <div className={styles.content}>
          {icon}
          <label>{infoMessage}</label>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
