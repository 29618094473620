import React from "react";
import {
  CategoryReport,
  VigieInfoByBanks,
  VigieInfobyType,
} from "../../../models/vigie-info";
import BankTabs from "./banks";
import TypeTabs from "./type";
import { Loader } from "../../../components";
import ReportTab from "./report";

export interface TabsProps {
  selectedTabKey?: string;
  vigieInfo: VigieInfoByBanks[] | VigieInfobyType[] | CategoryReport;
  isLoading?: boolean;
  onVigieAccept: (data: any) => void;
  onVigieReject: (data: any) => void;
}
const CustomTabs: React.FunctionComponent<TabsProps> = ({
  selectedTabKey,
  vigieInfo,
  isLoading,
  onVigieAccept,
  onVigieReject,
}: TabsProps) => {
  if (isLoading) return <Loader show={isLoading} />;
  if (vigieInfo && selectedTabKey === "type")
    return (
      <TypeTabs
        onVigieAccept={onVigieAccept}
        onVigieReject={onVigieReject}
        vigieInfo={vigieInfo}
      />
    );
  if (vigieInfo && selectedTabKey === "bank")
    return (
      <BankTabs
        onVigieAccept={onVigieAccept}
        onVigieReject={onVigieReject}
        vigieInfo={vigieInfo as VigieInfoByBanks[]}
      />
    );

  if (vigieInfo && selectedTabKey === "report")
    return <ReportTab vigieInfo={vigieInfo as CategoryReport} />;

  return <Loader show={isLoading} />;
};

export default CustomTabs;
