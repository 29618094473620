import { VigieType } from "../models/vigie-info";
import { DateTime } from "luxon";

export default {
  common: {
    mapRateCategory: (type: keyof VigieType): string => {
      switch (type) {
        case "savings":
          return "Épargne";
        case "mortgage":
          return "Hypothécaire";
        default:
          return "Inconnue";
      }
    },
    loadingVigie: "Récupération des vigies ...",
    noRateAggregated:
      "Les vigies de la journée n'ont pas été récupérées ou il y a eu une erreur lors la récupération. Veuillez revenir plus tard.",
    noValue: "Aucune valeur",
    accept: "Accepter",
    reject: "Refuser",
    submit: "Soumettre",
    download: "Télécharger",
    send: "Envoyer",
    noInterdayChange: "Aucun changement détecté pour cette catégorie",
  },
  notification: {
    unexpectedError: "Erreur inattendue",
  },
  auth: {
    login: {
      connect: "Se connecter",
      username: "Identifiant",
      yourUsername: "desjardins-admin",
      password: "Mot de passe",
      yourPassword: "Votre mot de passe",
      connection: "Connexion",
      forgotten: "Oublié?",
      errors: {
        invalidUsername: "Identifiant invalide",
        requiredField: "Champs requis",
        passwordRequired: "Mot de passe requis",
      },
    },
  },
  home: {},
  header: {
    contactUs: "Contactez-nous",
    contactEmail: "aggregateur.support@desjardins.com",
    logoDesjardins: "Logo desjardins - Aggrégateur",
    logout: "Se déconnecter",
  },
  components: {
    fileUpload: {
      fileTooLarge: "Le fichier est trop gros",
      invalidFileType: "Le type de fichier est invalide",
      uploadFile: "Téléversez un fichier",
      readingFile: "Erreur lors de la lecture de fichier",
    },
    tabs: {
      noRateImplemented: "Aucun taux disponible pour ce type",
      acceptedAt: (dateString: string): string =>
        `Cette vigie a été acceptée le ${DateTime.fromISO(
          dateString
        ).toLocaleString({
          timeStyle: "medium",
          dateStyle: "full",
        })}`,
      rejectedAt: (dateString: string): string =>
        `Cette vigie a été refusée le ${DateTime.fromISO(
          dateString
        ).toLocaleString({
          timeStyle: "medium",
          dateStyle: "full",
        })}`,
    },
    rejectionForm: {
      uploadFileTitle: "Veuillez sélectionner un fichier",
      rejectionJutificationTitle:
        "Veuillez entrer une justification pour le rejet de la vigie",
      rejectionJustificationPlaceholder: "Entrer une justification",
    },
    effectiveChange: {
      added: "Ajouter",
      updated: "Mise à jour",
      deleted: "Supprimer",
      effectiveChangeTitle: "Changements effectués",
      todaysChange: "Changements intra-journaliers",
      infoUrl: "URL de l'information (opt)",
      oldValueHeader: "Ancienne",
      deletedValue: "Données supprimées",
      newValueHeader: "Nouvelle",
      detailsHeader: "Détails",
    },
  },
  global: {
    errors: {
      defaultError: "Oups! Une erreur est survenue.",
    },
  },
};
