import React, { useState } from "react";
import { Button, FileUpload } from "../../index";
import texts from "../../../constants/texts";
import { FormStepProps } from "../props";

const FormStep2: React.FunctionComponent<FormStepProps> = ({
  onSubmit,
  isLoading,
}: FormStepProps) => {
  const t = texts.components.rejectionForm;
  const [file, setFile] = useState<File | undefined>();
  const _onSubmit = () => {
    if (onSubmit) onSubmit({ file: file });
  };
  return (
    <div className={`d-flex flex-column`}>
      <p>{t.uploadFileTitle}</p>
      <FileUpload filename={file?.name} onChange={setFile} />
      <Button
        disabled={!file}
        className="mt-2 mx-0"
        loading={isLoading}
        onClick={_onSubmit}
      >
        {texts.common.submit}
      </Button>
    </div>
  );
};

export default FormStep2;
