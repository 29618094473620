import React, { useEffect } from "react";
import "./App.css";
import Axios from "axios";
import { useLayoutEffect, useState } from "react";
import { getPersistedAuthToken, persistAuthToken } from "./utils/local-storage";
import { Redirect, Route, Switch } from "react-router-dom";
import User from "./models/user";
import { get } from "lodash.get";
import AppContext from "./context/app";
import LoginPage from "./pages/login";
import { ROUTES } from "./constants/routes";
import { Header } from "./components";
import HomePage from "./pages/home";
import { ME } from "./constants/endpoints";
const App: React.FunctionComponent = () => {
  const [authToken, setAuthToken] = useState<string | null>(
    getPersistedAuthToken()
  );
  const [authUser, setAuthUser] = useState<User | null>(null);

  useLayoutEffect(() => {
    // Persist the token
    persistAuthToken(authToken);

    // If no auth token, no auth user
    if (!authToken) setAuthUser(null);

    // Update axios configs to change the used auth token
    const requestInterceptor = Axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${authToken}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      Axios.interceptors.request.eject(requestInterceptor);
    };
  }, [authToken, setAuthUser]);
  useEffect(() => {
    // Fetch auth user
    if (authToken !== null && authToken !== undefined) fetchMe();
  }, [authToken]);

  useEffect(() => {
    const responseInterceptor = Axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const errorStatus = get(
          error,
          ["response", "data", "error", "status"],
          400
        );
        if (errorStatus === 401 || errorStatus === 403) setAuthUser(null);
        return Promise.reject(error);
      }
    );

    return () => {
      Axios.interceptors.request.eject(responseInterceptor);
    };
  }, [setAuthToken]);

  // Networking
  const fetchMe = async () => {
    try {
      const { data } = await Axios.get(ME);
      setAuthUser(data);
    } catch (e) {
      setAuthToken(null);
    }
  };

  // Rendering
  return (
    <AppContext.Provider
      value={{ authUser, setAuthUser, authToken, setAuthToken }}
    >
      <Header />
      <Switch>
        <Route exact path={ROUTES.LOGIN}>
          <LoginPage />
        </Route>
        {!authToken && <Redirect to={ROUTES.LOGIN} />}

        <Route path={ROUTES.HOME}>
          <HomePage />
        </Route>
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
