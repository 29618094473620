import React, { useState, useEffect } from "react";
import styles from "./popover.module.css";

type PopoverAlignement = "left" | "right" | "center";

type PopoverPosition = "top" | "bottom";

interface PopoverInterface {
  children?: React.ReactElement;
  className?: string;
  shown?: boolean;
  align?: PopoverAlignement;
  position?: PopoverPosition;
  actionWillHide?: boolean;
  onChange?: (shown: boolean) => void;
}

const Popover: React.FunctionComponent<PopoverInterface> = ({
  children,
  className,
  align = "left",
  position = "top",
  shown = false,
  actionWillHide = false,
  onChange,
}: PopoverInterface) => {
  // States and ref

  const [_shown, setShown] = useState(false);

  // Effects
  useEffect(() => {
    if (shown) {
      window.addEventListener("click", hide);
    } else {
      window.removeEventListener("click", hide);
    }

    setShown(shown);
  }, [shown]);

  useEffect(() => {
    if (onChange) onChange(_shown);
  }, [_shown]);

  // Handlers

  const hide = () => {
    if (!actionWillHide) setShown(false);
  };

  // Rendering

  let rootClass = styles.popover;
  if (className) rootClass += ` ${className}`;
  if (_shown) rootClass += ` ${styles.shown}`;

  let alignCenter;
  let translate = "translateY(calc(-100% - 30px))";
  if (align === "center") {
    alignCenter = "50%";
    translate = "translate(-50%,calc(-100% - 30px))";
  }
  const rootStyle = {
    right: align === "right" ? 0 : undefined,
    left: align === "left" ? 0 : alignCenter,
    transform: position === "top" ? translate : "translateY(20px)",
  };

  const arrowStyle = {
    right: align === "right" ? "2em" : undefined,
    left: align === "left" ? "2em" : align === "center" ? "50%" : undefined,
    bottom: position === "top" ? "-0.75em" : undefined,
  };

  return (
    <div className={rootClass} style={rootStyle}>
      <div className={styles.arrow} style={arrowStyle} />
      <div className={styles.content}>
        <div className={styles.inner} onClick={(e) => e.stopPropagation()}>
          {_shown && children}
        </div>
      </div>
    </div>
  );
};

export default Popover;
