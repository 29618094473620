import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  ImplementedVigieCategory,
  KeyValueObj,
  mapBankImgToIdentifier,
  VigieInfoByBanks,
  VigieType,
} from "../../../../models/vigie-info";
import { TabsProps } from "../index";
import styles from "../tab.module.css";
import texts from "../../../../constants/texts";
import {
  Button,
  Divider,
  EffectiveChange,
  Popover,
  VigieRejectionForm,
} from "../../../../components";
import { AlertTriangle, Check, X } from "react-feather";
import Axios from "axios";
import {
  ACCEPT_VIGIE,
  REJECT_VIGIE,
  VIGIE_RATE,
} from "../../../../constants/endpoints";
import { PopoverEffectiveChange } from "../type";
import { EffectiveChangeInfo } from "../../../../models/effective-change";
import useAuthUser from "../../../../hooks/auth-user";
import get from "lodash.get";
import { NotificationManager } from "react-notifications";

export interface PopoverShowObj {
  [key: string]: boolean;
}
interface Props extends TabsProps {
  vigieInfo: VigieInfoByBanks[];
}
const BankTabs: React.FunctionComponent<Props> = ({
  vigieInfo,
  onVigieAccept,
  onVigieReject,
}: Props) => {
  // State
  const [popoverIsShownObj, setPopoverIsShowObj] = useState<PopoverShowObj>({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [authUser] = useAuthUser();
  const [
    effectiveChange,
    setEffectiveChange,
  ] = useState<PopoverEffectiveChange>({});

  // Networking

  const downloadVigie = async (
    rateCategory: ImplementedVigieCategory,
    rateInfo: KeyValueObj,
    bankIdentifier: string
  ) => {
    setIsDownloading(true);
    const today = new Date().toISOString().split("T")[0];
    const mortgageRateType =
      rateCategory === "mortgage" ? `&rate-type=${rateInfo.key}` : "";
    const response = await Axios.get(
      `${VIGIE_RATE(
        rateCategory,
        rateInfo.key
      )}/temp?dates=${today}&bank-identifiers=${bankIdentifier}&format=xlsx${mortgageRateType}`,
      { responseType: "blob" }
    );

    setIsDownloading(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${rateCategory.toUpperCase()}_${rateInfo.key.toUpperCase()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onEffectiveChangeAccept = async (
    popoverKey: string,
    categorie: ImplementedVigieCategory,
    rateKey: string,
    infoUrl?: string
  ) => {
    const payload = new FormData();
    const { file, justification } = effectiveChange[popoverKey];

    if (!file || !justification) return;

    payload.append("file", file);
    payload.append("justification", justification);
    payload.append(
      "effectiveChange",
      JSON.stringify(effectiveChange[popoverKey].effectiveChange)
    );
    if (infoUrl) payload.append("url", infoUrl);

    const axiosInstance = Axios.create({
      baseURL: process.env.REACT_APP_API_URL || "http://localhost:9000",
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authUser?.token}`,
      },
    };

    const mortgageRateType =
      categorie === "mortgage" ? `&rate-type=${rateKey}` : "";
    const url = `${REJECT_VIGIE(
      categorie,
      rateKey
    )}?vigie-info=bank${mortgageRateType}`;

    try {
      const { data } = await axiosInstance.put(url, payload, config);
      const effectiveChangeCopy = { ...effectiveChange };
      delete effectiveChangeCopy[popoverKey];
      setEffectiveChange(effectiveChangeCopy);
      onVigieReject(data);
    } catch (error) {
      const msg = get(
        error,
        "response.data.error.msg",
        texts.notification.unexpectedError
      );
      NotificationManager.error(msg);
      console.log(error);
    }
  };
  const acceptVigie = async (
    categorie: ImplementedVigieCategory,
    rateInfo: KeyValueObj,
    bankIdentifier: string
  ) => {
    setIsAccepting(true);
    const mortgageRateType =
      categorie === "mortgage" ? `&rate-type=${rateInfo.key}` : "";
    const url = `${ACCEPT_VIGIE(
      categorie,
      rateInfo.key
    )}?bank-identifiers=${bankIdentifier}&vigie-info=bank${mortgageRateType}`;

    const { data } = await Axios.put(url);
    onVigieAccept(data);
    setIsAccepting(false);
  };

  // Handlers
  const onVigieEffectiveChangeReceived = (
    effChange: any,
    popoverKey: string
  ) => {
    setEffectiveChange({
      ...effectiveChange,
      [popoverKey]: effChange,
    });
    setPopoverIsShowObj({
      ...popoverIsShownObj,
      [popoverKey]: false,
    });
  };

  // Rendering

  const renderEffectiveChange = (
    popoverKey: string,
    categorie: ImplementedVigieCategory,
    rateKey: string
  ) => {
    if (effectiveChange[popoverKey]) {
      return (
        <EffectiveChange
          onEffectiveChangeAccept={(url?: string) =>
            onEffectiveChangeAccept(popoverKey, categorie, rateKey, url)
          }
          effectiveChange={effectiveChange[popoverKey].effectiveChange}
        />
      );
    }
  };

  const renderTabInfo = (
    bankInfo: VigieInfoByBanks,
    type: keyof VigieType,
    rateInfoArr: KeyValueObj[]
  ) => {
    const t = texts.components.tabs;

    // Rendering
    const renderRejectionPopOver = (
      rateKeyValue: KeyValueObj,
      identifier: string
    ) => {
      const popoverKey = `popover-${identifier}-${rateKeyValue.key}`;
      const isShown = popoverIsShownObj[popoverKey] ?? false;
      return (
        rateKeyValue.approvedAt === null &&
        rateKeyValue.rejectedAt === null && (
          <div className={styles.rejectionHolder}>
            <Button
              type={"secondary"}
              onClick={() =>
                setPopoverIsShowObj({
                  ...popoverIsShownObj,
                  [popoverKey]: true,
                })
              }
            >
              {texts.common.reject}
            </Button>
            <Popover
              onChange={(isShown) =>
                setPopoverIsShowObj({
                  ...popoverIsShownObj,
                  [popoverKey]: isShown,
                })
              }
              key={popoverKey}
              align="center"
              position="top"
              shown={isShown}
            >
              <VigieRejectionForm
                setEffectiveChange={(eff: EffectiveChangeInfo) =>
                  onVigieEffectiveChangeReceived(eff, popoverKey)
                }
                rateCategory={type}
                rateKey={rateKeyValue.key}
                bankIdentifier={bankInfo.identifier}
              />
            </Popover>
          </div>
        )
      );
    };

    return (
      <div className={`${styles.tabInfoContainer} pt-4`}>
        <h2>Vigie {texts.common.mapRateCategory(type)}</h2>
        {rateInfoArr.length > 0
          ? rateInfoArr
              .sort((a, b) => a.order - b.order)
              .map((rateInfo, index) => (
                <>
                  <div
                    key={`${bankInfo.identifier}-${rateInfo.key}`}
                    className="d-flex align-center justify-space-between my-2"
                  >
                    <div className="d-flex align-center">
                      <h4>{rateInfo.value}</h4>
                      {renderVigieStatus(rateInfo)}
                    </div>
                    <div className={styles.actionBtnContainer}>
                      {rateInfo.approvedAt === null &&
                        rateInfo.rejectedAt === null && (
                          <Button
                            disabled={isAccepting}
                            onClick={() =>
                              acceptVigie(type, rateInfo, bankInfo.identifier)
                            }
                            type={"secondary"}
                          >
                            {texts.common.accept}
                          </Button>
                        )}
                      {renderRejectionPopOver(rateInfo, bankInfo.identifier)}
                      <Button
                        onClick={() =>
                          downloadVigie(type, rateInfo, bankInfo.identifier)
                        }
                        loading={isDownloading}
                      >
                        {texts.common.download}
                      </Button>
                    </div>
                  </div>
                  {renderEffectiveChange(
                    `popover-${bankInfo.identifier}-${rateInfo.key}`,
                    type,
                    rateInfo.key
                  )}
                  <Divider />
                </>
              ))
          : t.noRateImplemented}
      </div>
    );
  };

  const renderVigieTabs = () => {
    return vigieInfo?.map((info: any) => (
      <TabPanel key={`${info.identifier}_panel`}>
        <Tabs forceRenderTabPanel className={styles.vigieInfoContainer}>
          {(Object.entries(info.vigie) as [keyof VigieType, KeyValueObj[]][])
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([type, rateInfo]) => renderTabInfo(info, type, rateInfo))}
        </Tabs>
      </TabPanel>
    ));
  };

  const renderVigieStatus = (rateInfo: KeyValueObj) => {
    if (rateInfo.approvedAt !== null)
      return (
        <span className={styles.isApproved}>
          <Check />
        </span>
      );
    if (rateInfo.rejectedAt !== null)
      return (
        <span className={styles.isRejected}>
          <X />
        </span>
      );
  };

  return (
    <>
      {vigieInfo.length === 0 && (
        <div className={styles.errorContainer}>
          <AlertTriangle className="mx-2" />
          <span>{texts.common.noRateAggregated}</span>
        </div>
      )}
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <TabList>
          {vigieInfo?.map((info: any) => (
            <Tab key={info.identifier}>
              <img
                height="48px"
                src={mapBankImgToIdentifier(info.identifier)}
              />
            </Tab>
          ))}
        </TabList>
        {renderVigieTabs()}
      </Tabs>
    </>
  );
};

export default BankTabs;
