import React from "react";
import styles from "./divider.module.css";
const Divider: React.FunctionComponent = () => {
  return (
    <div className={styles.divider}>
      <span />
    </div>
  );
};
export default Divider;
