const AUTH_TOKEN_KEY = "desjAgg:authToken";

export const persistAuthToken = (token: string | null): void => {
  if (!token) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  } else {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  }
};
export const getPersistedAuthToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};
