import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import get from "lodash.get";
import Axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import { BrowserRouter } from "react-router-dom";

// Global styles
import "./index.css";
import "./utilities.css";
import texts from "./constants/texts";

// Axios global configs

Axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:9000";

Axios.interceptors.response.use(
  (res) => res,
  (error) => {
    const errorMsg = get(
      error,
      ["response", "data", "error", "msg"],
      texts.notification.unexpectedError
    );

    NotificationManager.error(errorMsg);

    return Promise.reject(error);
  }
);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <NotificationContainer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
