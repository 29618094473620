import React, { useState } from "react";
import texts from "../../constants/texts";
import Axios from "axios";
import { EFFECTIVE_CHANGE } from "../../constants/endpoints";
import { FormProps, RejectionFormObj } from "./props";
import Animate from "../animate";
import FormStep2 from "./step2";
import FormStep1 from "./step1";
import { NotificationManager } from "react-notifications";
import useAuthUser from "../../hooks/auth-user";
import get from "lodash.get";
const DEFAULT_FORM: RejectionFormObj = {
  step: 0,
};
const VigieRejectionForm: React.FunctionComponent<FormProps> = ({
  rateKey,
  bankIdentifier,
  rateCategory,
  setEffectiveChange,
}: FormProps) => {
  const [authUser] = useAuthUser();
  const [form, setForm] = useState<RejectionFormObj>(DEFAULT_FORM);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (rejectionForm: RejectionFormObj) => {
    const formData = { ...form, ...rejectionForm };
    setForm(formData);
  };

  const sendNewRateFile = async (rejectionForm: RejectionFormObj) => {
    try {
      setForm({ ...form, ...rejectionForm });

      const payload = new FormData();
      setIsLoading(true);
      const { justification } = form;
      const { file } = rejectionForm;
      if (!file || !justification) return;

      payload.append("file", file);
      payload.append("justification", justification);

      const axiosInstance = Axios.create({
        baseURL: process.env.REACT_APP_API_URL || "http://localhost:9000",
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authUser?.token}`,
        },
      };

      let url = EFFECTIVE_CHANGE(rateCategory, rateKey);
      const params = bankIdentifier
        ? `?bank-identifiers=${bankIdentifier}`
        : undefined;
      const spliter = params ? "&" : "?";
      const mortgageRateType =
        rateCategory === "mortgage" ? `${spliter}rate-type=${rateKey}` : "";

      if (params) url += params;

      url += mortgageRateType;

      // We need to create an axios instance we only want those config for this request

      const { data } = await axiosInstance.post(url, payload, config);
      if (setEffectiveChange)
        setEffectiveChange({ effectiveChange: data, file, justification });
    } catch (error) {
      const msg = get(
        error,
        "response.data.error.msg",
        texts.notification.unexpectedError
      );
      NotificationManager.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Animate
        animationDurationIn={300}
        animationDurationOut={300}
        isVisible={form.step === 0}
      >
        <FormStep1 onSubmit={onSubmit} />
      </Animate>

      <Animate
        isVisible={form.step === 1}
        animationDelayIn={300}
        animationDurationIn={300}
        animationDurationOut={300}
      >
        <FormStep2 onSubmit={sendNewRateFile} isLoading={isLoading} />
      </Animate>
    </div>
  );
};

export default VigieRejectionForm;
