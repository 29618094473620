import React, { useState } from "react";
import { Button, Field } from "../../index";
import styles from "../../../pages/home/home.module.css";
import { FormStepProps } from "../props";
import texts from "../../../constants/texts";

const FormStep1: React.FunctionComponent<FormStepProps> = ({
  onSubmit,
}: FormStepProps) => {
  const [justification, setJustification] = useState<string | undefined>();
  const t = texts.components.rejectionForm;
  return (
    <div className={`d-flex flex-column flex-grow-1`}>
      <p>{t.rejectionJutificationTitle}</p>
      <Field
        fieldClassName={styles.textArea}
        textArea={true}
        className="d-flex flex-grow-1 flex-column"
        placeholder={t.rejectionJustificationPlaceholder}
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
      />
      <Button
        className="my-2 mx-0"
        onClick={() => onSubmit({ step: 1, justification: justification })}
        disabled={!justification}
      >
        {texts.common.submit}
      </Button>
    </div>
  );
};

export default FormStep1;
